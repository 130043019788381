import { render, staticRenderFns } from "./VRMViewThree.vue?vue&type=template&id=041f0f15&scoped=true&"
import script from "./VRMViewThree.vue?vue&type=script&lang=ts&"
export * from "./VRMViewThree.vue?vue&type=script&lang=ts&"
import style0 from "./VRMViewThree.vue?vue&type=style&index=0&id=041f0f15&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041f0f15",
  null
  
)

export default component.exports